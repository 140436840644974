<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="所在单位">
          <el-select v-model="searchForm.unitName" placeholder="请选择所在单位" clearable filterable remote :remote-method="remoteMethod" size="small">
            <el-option v-for="(item,i) in unitOptions" :key="i" :label="item.unitName" :value="item.unitName">
              <span style="float: left">{{ item.unitName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.address }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus">新增培训</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="trainList" stripe style="width: 100%" height="100%">
        <el-table-column prop="title" label="标题" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="userName" label="学员名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="idCard" label="身份证号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="phoneNumber" label="电话号码" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="trainDate" label="培训时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.trainDate|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="releaseTime" label="发布时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.releaseTime|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tooltip content="查看" placement="top" effect="dark">
              <el-button type="primary" circle icon="el-icon-view" size="small" @click="open('content',scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip content="修改" placement="top" effect="dark">
              <el-button type="warning" circle icon="el-icon-edit" size="small" @click="open('edit',scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip content="删除" placement="top" effect="dark">
              <el-button type="danger" circle icon="el-icon-delete" size="small" @click="delTrain(scope.row.id)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <el-dialog :title="optFlag?'发布培训':'修改培训'" :visible.sync="trainShow" width="40%" top="3%" v-dialogDrag>
      <el-form :model="trainForm" ref="trainForm" :rules="rules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="trainForm.title" size="small" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="学员名称" prop="userName">
          <el-input v-model="trainForm.userName" size="small" placeholder="请输入学员名称"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="idCard">
          <el-input v-model="trainForm.idCard" size="small" placeholder="请输入身份证号"></el-input>
        </el-form-item>
        <el-form-item label="电话号码" prop="phoneNumber">
          <el-input v-model="trainForm.phoneNumber" size="small" maxlength="11" show-word-limit placeholder="请输入电话号码"></el-input>
        </el-form-item>
        <el-form-item label="培训时间" prop="trainDate">
          <el-date-picker v-model="trainForm.trainDate" type="datetime" placeholder="选择培训时间" size="small" style="float:left">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="内容">
          <editor-bar v-model="trainForm.content" :isClear="isClear" @change="changeContent"></editor-bar>
        </el-form-item>
      </el-form>
      <span>
        <el-button @click="trainShow = false">取 消</el-button>
        <el-button type="primary" @click="addTrain" v-show="optFlag">发布培训</el-button>
        <el-button type="primary" @click="updateTrain" v-show="!optFlag">修改培训</el-button>
      </span>
    </el-dialog>
    <el-dialog title="培训内容" :visible.sync="contentShow" width="40%" top="3%">
      <div class="train-content">
        <div class="content" v-html="trainForm.content" v-show="trainForm.content!==''">
        </div>
        <div class="content" v-show="trainForm.content===''" style="text-align:center">
          暂无内容
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import EditorBar from '../../../components/Editor'
export default {
  components: {
    EditorBar
  },
  data () {
    return {
      currentPage: 1,
      total: 0,
      optFlag: true,
      contentShow: false,
      trainShow: false,
      trainList: [],
      trainId: 0,
      trainForm: {
        title: '',
        content: ''
      },
      trainFormCopy: {},
      searchForm: {},
      unitOptions: [],
      isClear: false,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        idCard: [
          { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '身份证格式错误', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTable()
    this.trainFormCopy = JSON.stringify(this.trainForm)
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 初始化表格
    async initTable () {
      const data = {}
      data.pageNo = this.currentPage
      data.pageSize = 10
      const { data: result } = await this.$axios.get('/fireSkillTrain/list', { params: data })
      if (result.code === 200) {
        this.trainList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    async open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.trainForm = {
            title: '',
            content: ''
          }
          this.trainShow = true
          break
        case 'edit':
          this.optFlag = false
          this.trainShow = true
          this.$nextTick(() => {
            this.$refs.trainForm.clearValidate()
          })
          this.trainForm = JSON.parse(JSON.stringify(row))
          this.trainId = row.id
          break
        case 'content':
          this.contentShow = true
          this.trainForm = JSON.parse(JSON.stringify(row))
          break
      }
    },
    // 添加培训
    addTrain () {
      this.$refs.trainForm.validate(async valid => {
        if (valid) {
          if (this.searchForm.content === '') {
            this.$message.error('请输入培训')
          }
          const { data: result } = await this.$axios.post('/fireSkillTrain/add', this.trainForm)
          if (result.code === 200) {
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.$message.success('添加成功')
            this.trainShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除培训
    async delTrain (id) {
      const confirm = await this.$confirm('此操作将永久删除该培训, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/fireSkillTrain/del/${id}`)
        if (result.code === 200) {
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.$message.success('删除成功')
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改培训
    async updateTrain () {
      this.$refs.trainForm.validate(async valid => {
        if (valid) {
          this.trainForm.id = this.trainId
          const { data: result } = await this.$axios.put(`/fireSkillTrain/edit/${this.trainId}`, this.trainForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.trainShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async remoteMethod (query) {
      const { data: result } = await this.$axios.get('/unit/listAllUnit', { params: { unitName: query } })
      if (result.code === 200) {
        this.unitOptions = result.data
      }
    },
    changeContent (val) {

    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.el-dialog {
  .el-dialog__body {
    .el-form {
      ::v-deep .editor {
        .text {
          background-color: #fff;
        }
      }
    }
    .train-content {
      padding: 50px 100px;
      width: 100%;
      border: 1px solid #e2e2e2;
      box-sizing: border-box;
      color: #fff;
    }
  }
}
</style>
